import MockupBOT from '../src/assets/images/products/mockups/bot-mockup-slanted.png';
import MockupSD from '../src/assets/images/products/mockups/smartdisplays.png';
import MockupSMOP from '../src/assets/images/products/mockups/smartmobilityplanner.png';
import MockupBroadcast from '../src/assets/images/products/shots/broadcast-news-rtbf.png';

export const data = {
  pageTitle: 'Belgian Mobility Digital Factory – Stoomlink',
  title: 'We are Stoomlink.<br/>We are the Belgian Mobility<br/>Digital Factory.',

  products: [
    {
      titles: [
        'We help <span class="focus-main">companies</span><br />reinvent their <span class="focus-main">mobility</span>.',
        'We help <span class="focus-main">businesses</span><br/>reach more <span class="focus-main">clients</span>.',
        'We help <span class="focus-main">cities</span> design <br />better <span class="focus-main">transit</span> networks.',
      ],
      content: 'We are Stoomlink, a subsidiary of the <span class="focus-accent">four Belgian Public Transports Operators</span> — TEC, De Lijn, STIB-MIVB and NMBS-SNCB.<br/><br/>We build <span class="focus-accent">innovative digital applications</span> for the <span class="focus-accent">mobility of tomorrow</span>.',
      buttons: [
        { title: 'Join our newsletter', newsletterCTA: true },
      ],
      src: MockupBOT,
      parralax: [-90, -10],
    },

    {
      title: 'We allow <span class="focus-main">millions of Belgians</span><br/>to get around faster than ever.',
      content: 'Our SmartDisplays are popping all around Belgium.<br/>Get instant access to your surrounding mobility,<br/>right from an interactive on-street digital kiosk.<br/><br/>Discover how we helped <span class="focus-accent">TEC</span> and <span class="focus-accent">SNCB/NMBS</span><br/>make customer\'s journeys faster and simpler.<br/>Now available for <span class="focus-accent">your office </span> as well!',
      buttons: [{ title: 'Discover our Digital Signage solutions', href: '/smartdisplays' }],
      src: MockupSD,
      parralax: [-32, -10],
    },
    {
      title: 'We built the first ever<br/><span class="focus-main">real-time route planner</span><br/> in Belgium.',
      content: 'Meet <span class="focus-accent">SMOP</span> (for <b>S</b>mart <b>MO</b>bility <b>P</b>lanner). <br/>Our fully-featured intermodal, real-time route planner<br/>is ready to guide you through your journey.<br/><br/>Built using the best <span class="focus-accent">open-source technologies</span> - with a Belgian twist, of course!<br/><br/>Now build yours, using our ready-to-use APIs<br/>',
      buttons: [
        { title: 'Try it for yourself!', href: 'https://smartmobilityplanner.be', target: '_blank' },
        { title: 'Discover our Services & APIs', href: '/smartmobilityplanner', note: 'Services' },
      ],
      src: MockupSMOP,
      parralax: [-12, 0],
    },
    {
      title: 'We <span class="focus-main">broadcast</span><br/>the future of mobility, every day on <span class="focus-main">National TV</span>.',
      content: 'Broadcast helps <span class="focus-accent">RTBF</span> give the mobility of tomorrow<br/>the place it deserves on national news.<br/><br/>We are convinced the <span class="focus-accent">right information</span> at the right time is key<br/>to make the modal shift happen. Help us spread the word.<br/>Offer your audience the pulse of the green mobility network.',
      buttons: [{ title: 'Discover Broadcast', href: '/broadcast', note: 'Our tool for media agencies' }],
      src: MockupBroadcast,
      parralax: [-32, 0],

    },
  ],
  about: {
    title: 'We\'re not just another<br/><span class="focus-light">overfunded</span> mobility startup.',
    content: [
      {
        heading: 'We are young.',
        sentence: "And yet we've been doing this for 10 years.",
      },
      {
        heading: 'We are public-owned.',
        sentence: 'And a proud subsidiary of TEC, DeLijn, STIB-MIVB and NMBS-SNCB.',
      },
      {
        heading: 'We live by our vision.',
        sentence: 'And we know this changes everything.',
      },
    ],
    src: MockupSD,
    buttons: [{ title: 'Read our DNA', href: '/team' }],
  },
};

export default data;
