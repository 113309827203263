import React from 'react';

import BodyText from 'stoomlink-commons-ui/BodyText';
import LayersManager from 'stoomlink-commons-ui/LayersManager';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import Title from 'stoomlink-commons-ui/Title/Title';
import styled from 'styled-components';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';

import Button from '../components/Button';

import Hero from '../components/Hero';
import MailSection from '../components/MailSection';
import TextAndPicture from '../components/TextAndPicture';
import FloatingParagraph from '../components/FloatingParagraph';
import LogoWall from '../components/LogoWall';
import Footer from '../components/Footer';
import Card from '../components/Card';

// =============================================================================
// Content
// =============================================================================
import { data } from '../../content/index';
import Container from '../layouts/Container';

// =============================================================================
// Page
// =============================================================================

const AboutContent = styled.div`
  margin: 16px 0;
  box-sizing: border-box;
`;

const AboutSection = styled.section`
  margin-top: 64px;
  @media (max-width: 768px){
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const ProductSection = styled.section`
    margin-bottom: 64px;
  @media (max-width: 768px){
    margin-bottom: 32px;
  }
`;


const IndexPage = () => {
  const {
    pageTitle,
    title,
    summary,
    products,
    about,
  } = data;
  return (
    <ThemeProvider themeStyle="light">
      <LayersManager>
        <Layout>
          <Head title={pageTitle} />
          <Hero title={title}>
            <BodyText>
              {summary}
            </BodyText>
          </Hero>
          <ProductSection>
            {products.map((product, index) => {
              const layout = (index % 2 === 0) ? 'odd' : 'even';
              const {
                title: productTitle,
                titles,
                content, src, buttons, parralax,
              } = product;
              return (
                <TextAndPicture src={src} parallax={parralax} layout={layout} key={`${index.toString() + productTitle}`}>
                  <FloatingParagraph
                    title={productTitle}
                    titles={titles}
                    buttons={buttons}
                    key={`${index.toString() + productTitle}`}
                  >
                    {content}
                  </FloatingParagraph>
                </TextAndPicture>
              );
            })}
          </ProductSection>
          <LogoWall />
          <AboutSection>
            <Container>
              <Card theme="secondary" style={{ marginBottom: '0' }}>
                <Title
                  dangerouslySetInnerHTML={{ __html: about.title }}
                  displayLevel={3}
                />
                {about.content.map((el, i) => {
                  return (
                    <AboutContent key={`${el.heading + i}`}>
                      <Title level={2}>
                        {el.heading}
                      </Title>
                      <BodyText type="subhead">
                        {el.sentence}
                      </BodyText>
                    </AboutContent>
                  );
                })}
                <Button href="/team" kind="secondary">About us</Button>
              </Card>
              <MailSection />
            </Container>
          </AboutSection>
          <Footer />
        </Layout>
      </LayersManager>
    </ThemeProvider>
  );
};

export default IndexPage;
